import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { CalculatorInput, Tooltip, Toggle } from '@cardo/ui';
import { formatInt } from '~/lib/utils';
import CalculatorSection from '../components/CalculatorSection';
import CalculatorStructure from '../components/CalculatorStructure';
import {
  multipliersReccuringIntervalOptions,
  useCalculator,
} from '~/hooks/useCalculator';
import type { NumberInputItem, Redemption } from '../types';
import IntroOfferSection from '../components/IntroOfferSection';
import ExpectedValue from '../components/ExpectedValue';
import RedemptionRadios from '../components/RedemptionRadios';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import ValueWithBar from '../components/ValueWithBar';

export default function AmericanExpressGoldCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const {
    state,
    dispatch,
    hasIntroOffer,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval,
    creditsError,
    setCreditsError,
    customCPPError,
    setCustomCPPError,
    totalCreditsValue,
    data,
    cpp,
    bonusValue,
  } = useCalculator();
  const isMonthly = multipliersReccuringInterval === 'Monthly';
  const { spendCategories, credits } = state;
  const { spendCategoryItems, creditsItems, redemptionItems, annualFee } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      creditsItems: NumberInputItem[];
      redemptionItems: Redemption[];
      annualFee: number;
    }) ?? {};

  const pointsEarned: {
    restaurants: number;
    supermarkets: number;
    flights: number;
    hotels: number;
    other: number;
    total: number;
  } = useMemo(() => {
    const { restaurants, supermarkets, flights, hotels, other } =
      spendCategories;

    const restaurantsSpendAnnually = isMonthly
      ? (restaurants ?? 0) * 12
      : restaurants ?? 0;
    const restaurantsSpendUpToLimit = Math.min(restaurantsSpendAnnually, 50000);
    const restaurantsSpendAfterLimit = Math.max(
      restaurantsSpendAnnually - 50000,
      0
    );
    const restaurantsPoints =
      restaurantsSpendUpToLimit * 4 + restaurantsSpendAfterLimit;

    const supermarketSpendAnnually = isMonthly
      ? (supermarkets ?? 0) * 12
      : supermarkets ?? 0;
    const supermarketSpendUpToLimit = Math.min(supermarketSpendAnnually, 25000);
    const supermarketSpendAfterLimit = Math.max(
      supermarketSpendAnnually - 25000,
      0
    );
    const supermarketsPoints =
      supermarketSpendUpToLimit * 4 + supermarketSpendAfterLimit;

    let flightsPoints = (flights || 0) * 3;
    let hotelsPoints = (hotels || 0) * 2;
    let otherPoints = other || 0;

    return {
      restaurants: restaurantsPoints,
      supermarkets: supermarketsPoints,
      flights: flightsPoints,
      hotels: hotelsPoints,
      other: otherPoints,
      total:
        restaurantsPoints +
        supermarketsPoints +
        flightsPoints +
        hotelsPoints +
        otherPoints,
    };
  }, [spendCategories, isMonthly]);

  const [limitedTimeBonusSpend, setLimitedTimeBonusSpend] = useState(500);
  const [limitedTimeBonusSpendError, setLimitedTimeBonusSpendError] = useState<
    string | null
  >(null);
  const limitedTimeBonusValue = useMemo(() => {
    return Math.min(limitedTimeBonusSpend * 0.2, 100);
  }, [limitedTimeBonusSpend]);

  const categoriesSpendValue = useMemo(() => {
    if (cpp) {
      return (
        ((multipliersReccuringInterval === 'Monthly'
          ? pointsEarned.total * 12
          : pointsEarned.total) *
          cpp) /
        100
      );
    }
    return 0;
  }, [cpp, pointsEarned.total, multipliersReccuringInterval]);

  const effectiveAnnualFee = useMemo(() => {
    return annualFee - totalCreditsValue;
  }, [annualFee, totalCreditsValue]);

  const totalValueOfPoints = useMemo(() => {
    return bonusValue + categoriesSpendValue;
  }, [bonusValue, categoriesSpendValue]);

  const totalValuePerYear = useMemo(() => {
    if (
      !state.redemption.selectedOption ||
      (state.redemption.selectedOption === 'custom' &&
        !state.redemption.customValue) ||
      cpp === 0 ||
      customCPPError
    )
      return 0;
    return totalCreditsValue + categoriesSpendValue - annualFee;
  }, [
    totalCreditsValue,
    categoriesSpendValue,
    state.redemption,
    annualFee,
    customCPPError,
    cpp,
  ]);

  const year1Value = useMemo(() => {
    return totalValuePerYear + bonusValue + limitedTimeBonusValue;
  }, [totalValuePerYear, bonusValue, limitedTimeBonusValue]);

  const year2Value = useMemo(() => {
    return totalValuePerYear;
  }, [totalValuePerYear]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="American Express® Gold Card"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="American Express Gold Card Intro Offer"
            colorClassName="border-b-theme-yellow"
            introOffer={calculator.data?.attributes.introOfferSection}
            isMR={true}
          />
        )}
      <CalculatorSection
        heading="Limited Time Offer: 20% Back on Restaurants"
        splitHeading={true}
        colorClassName="border-b-theme-yellow"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex flex-col basis-1/2 px-6 pt-10 pb-5 gap-5">
            <p>
              20% back in statement credits for restaurant purchases in first 6
              months (up to $100 back). Offer ends 11/6/2024.
            </p>
            <div className="flex flex-col flex-grow w-full gap-2">
              <p>How much will you spend on restaurants in first 6 months?</p>
              <CalculatorInput
                currency={true}
                type="text"
                name="bonusSpend"
                value={limitedTimeBonusSpend}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const parsedValue = e.target.value
                    ? parseInt(e.target.value.replace(/,/g, ''))
                    : 0;
                  if (parsedValue > 500) {
                    setLimitedTimeBonusSpendError(
                      'Max qualifying spend is $500'
                    );
                  } else if (parsedValue < 0) {
                    setLimitedTimeBonusSpendError(
                      'Spend must be a positive number'
                    );
                  } else {
                    setLimitedTimeBonusSpendError(null);
                  }
                  setLimitedTimeBonusSpend(
                    isNaN(parsedValue) ? 0 : parsedValue
                  );
                }}
                className="flex flex-grow sm:w-full"
              />
              {!limitedTimeBonusSpendError && <div className="h-5 w-full" />}
              {limitedTimeBonusSpendError && (
                <div className="text-sm text-slate-500">
                  {limitedTimeBonusSpendError}
                </div>
              )}
            </div>
          </div>
          <div className="flex basis-1/2 flex-col items-center justify-center gap-3 px-8">
            <div className="flex flex-grow flex-col items-center justify-center gap-3">
              <h4 className="max-w-[300px] text-center">
                Statement credits earned from limited time intro offer:
              </h4>
              <span className="text-theme-blue-dark text-2xl font-bold">
                ${formatInt(limitedTimeBonusValue)}
              </span>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Credits: How Do You Value Them?"
        splitHeading={true}
        colorClassName="border-b-theme-yellow"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex flex-col 2xl:grid basis-1/2 grid-cols-2 px-6 pt-10 pb-5 gap-5 2xl:gap-0">
            {creditsItems.map((creditsItem, idx) => (
              <CalculatorInputGroup
                key={`${creditsItem.label}_${idx}`}
                className="2xl:col-span-2"
                {...creditsItem}
                stateObj={credits}
                stateKey="credits"
                dispatch={dispatch}
                error={creditsError}
                setError={setCreditsError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center gap-3 py-10 px-8">
            <p>
              The American Express Gold Card annual fee is $
              {formatInt(Math.round(data.annualFee))}.
            </p>
            <p>
              Given how you value the credits, your effective annual fee is:{' '}
              <span className="text-theme-blue-dark text-lg ml-2">
                ${formatInt(Math.round(effectiveAnnualFee))}
              </span>
            </p>
            {effectiveAnnualFee < 0 && (
              <p className="text-sm font-semibold text-gray-600">
                You're getting more value from the credits than the annual fee!
              </p>
            )}
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Point Multipliers: Category Spend"
        splitHeading={true}
        colorClassName="border-b-theme-yellow"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col px-6 py-10 gap-10">
            <div className="flex justify-end">
              <Toggle
                options={multipliersReccuringIntervalOptions}
                onChange={setMultipliersRecurringInterval}
                value={multipliersReccuringInterval === 'Yearly' ? 0 : 1}
                selectorClassName="bg-theme-yellow"
              />
            </div>
            {spendCategoryItems.map((category, idx) => (
              <div key={idx} className="flex gap-3 items-start sm:items-center">
                <img src={category.icon} alt={category.label} />
                <div className="flex flex-col sm:flex-row gap-2 sm:justify-between flex-grow items-start sm:items-center">
                  <div className="flex items-center gap-1">
                    <div className="w-fit">{category.label}</div>
                    {category.tooltip && (
                      <Tooltip
                        content={
                          <span className="text-sm max-w-[200px]">
                            {category.tooltip}
                          </span>
                        }
                      >
                        <IoInformationCircleOutline />
                      </Tooltip>
                    )}
                  </div>
                  <CalculatorInput
                    currency={true}
                    type="text"
                    name={category.name}
                    value={state.spendCategories[category.name]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const parsedValue = parseInt(
                        e.target.value.replace(/,/g, '')
                      );
                      dispatch({
                        type: 'update',
                        category: 'spendCategories',
                        name: e.target.name,
                        value: isNaN(parsedValue) ? null : parsedValue,
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex basis-1/2 justify-center items-center gap-3">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <p>{multipliersReccuringInterval} cash back breakdown:</p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={pointsEarned.restaurants}
                  comparisonValue={pointsEarned.total}
                  label="4X Restaurants"
                  colorClassName="bg-theme-blue-dark"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.supermarkets}
                  comparisonValue={pointsEarned.total}
                  label="4X U.S. Supermarkets"
                  colorClassName="bg-[#538DFF]"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.flights}
                  comparisonValue={pointsEarned.total}
                  label="3X Flights"
                  colorClassName="bg-[#C4FAFA]"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.hotels}
                  comparisonValue={pointsEarned.total}
                  label="2X Hotels"
                  colorClassName="bg-[#FFD6A5]"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.other}
                  comparisonValue={pointsEarned.total}
                  label="1X All other purchases"
                  colorClassName="bg-theme-purple"
                  suffix="MR"
                />
              </div>
              <div className="flex justify-between">
                <span>
                  Total points earned per{' '}
                  {multipliersReccuringInterval === 'Monthly'
                    ? 'month'
                    : 'year'}
                  :
                </span>
                <span className="text-theme-blue-dark">
                  {formatInt(Math.round(pointsEarned.total))} MR
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="How do you plan on redeeming points?"
        splitHeading={true}
        colorClassName="border-b-theme-yellow"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col px-6 py-10 gap-5">
            <RedemptionRadios
              redemptionItems={redemptionItems}
              redemption={state.redemption}
              dispatch={dispatch}
              setCustomCPPError={setCustomCPPError}
              customCPPError={customCPPError}
              header={
                <div className="flex flex-col gap-1">
                  <p>
                    American Express gives you many ways to redeem your points.
                    Here are some common examples:
                  </p>
                  <ul className="list-disc list-inside">
                    <li>Points are denoted as CPP = "cents per point"</li>
                  </ul>
                </div>
              }
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <h4>Given how you value points...</h4>
              <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                  <span>Value of points from intro offer:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(bonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>
                    Value of statement credits from limited time intro offer:
                  </span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(limitedTimeBonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value from category spend:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(categoriesSpendValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Total value of points:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(totalValueOfPoints))}
                  </span>
                </div>
              </div>
            </div>
            <ExpectedValue
              earnedIntroOffer={true}
              totalValuePerYear={totalValuePerYear}
              bonusValue={bonusValue}
              year1Value={year1Value}
              year2Value={year2Value}
              calculationExplanationText="(Intro offers + credits + spend - annual fee)"
              bgColorClassName="bg-theme-yellow"
              heading="Expected Value of Points"
            />
          </div>
        </div>
      </CalculatorSection>
    </CalculatorStructure>
  );
}
