import { useMemo } from 'react';
import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { formatInt } from '~/lib/utils';
import CalculatorSection from '../components/CalculatorSection';
import CalculatorStructure from '../components/CalculatorStructure';
import { Toggle, Tooltip } from '@cardo/ui';
import classNames from 'classnames';
import IntroOfferSection from '../components/IntroOfferSection';
import {
  getValue,
  multipliersReccuringIntervalOptions,
  useCalculator,
} from '~/hooks/useCalculator';
import ExpectedValue from '../components/ExpectedValue';
import RedemptionRadios from '../components/RedemptionRadios';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import ValueWithBar from '../components/ValueWithBar';
import type { NumberInputItem, Redemption } from '../types';
import { IoInformationCircleOutline } from 'react-icons/io5';
import HTMLContent from '../../HTMLContent';

export default function ChaseSapphirePreferredCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const {
    state,
    dispatch,
    hasIntroOffer,
    earnedIntroOffer,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval,
    perksError,
    setPerksError,
    customCPPError,
    setCustomCPPError,
    data,
    cpp,
    bonusValue,
  } = useCalculator();
  const { spendCategories, perks } = state;
  const { spendCategoryItems, perksItems, redemptionItems, annualFee } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      perksItems: NumberInputItem[];
      redemptionItems: Redemption[];
      annualFee: number;
    }) ?? {};

  const {
    hotelStaysViaChaseUltimateRewards,
    partnerPerksValue,
    travelPerksValue,
    totalPerksValue,
  }: {
    hotelStaysViaChaseUltimateRewards: number;
    partnerPerksValue: number;
    travelPerksValue: number;
    totalPerksValue: number;
  } = useMemo(() => {
    const { hotelStaysViaChaseUltimateRewards } = perks;
    const partnerPerksValue =
      getValue(data, state, 'perks', 'instacartCredit') +
      getValue(data, state, 'perks', 'doordashDashPassSubscription');

    const travelPerksValue =
      getValue(data, state, 'perks', 'primaryCollisionDamageWaiver') +
      getValue(data, state, 'perks', 'otherTravelProtections');

    const totalPerksValue =
      (hotelStaysViaChaseUltimateRewards ?? 0) +
      partnerPerksValue +
      travelPerksValue;
    return {
      hotelStaysViaChaseUltimateRewards: hotelStaysViaChaseUltimateRewards ?? 0,
      partnerPerksValue,
      travelPerksValue,
      totalPerksValue,
    };
  }, [data, perks, state]);

  const pointsEarned: {
    travelViaChaseUltimateRewards: number;
    lyftPurchases: number;
    dining: number;
    onlineGroceryPurchases: number;
    selectStreamingServices: number;
    otherTravelPurchases: number;
    allOtherPurchases: number;
    total: number;
    anniversaryBonus: number;
  } = useMemo(() => {
    const {
      travelViaChaseUltimateRewards,
      lyftPurchases,
      dining,
      onlineGroceryPurchases,
      selectStreamingServices,
      otherTravelPurchases,
      allOtherPurchases,
    } = spendCategories;

    const travelViaChaseUltimateRewardsPoints =
      (travelViaChaseUltimateRewards ?? 0) * 5;
    const lyftPurchasesPoints = (lyftPurchases ?? 0) * 5;
    const diningPoints = (dining ?? 0) * 3;
    const onlineGroceryPurchasesPoints = (onlineGroceryPurchases ?? 0) * 3;
    const selectStreamingServicesPoints = (selectStreamingServices ?? 0) * 3;
    const otherTravelPurchasesPoints = (otherTravelPurchases ?? 0) * 2;
    const allOtherPurchasesPoints = (allOtherPurchases ?? 0) * 1;

    const total =
      travelViaChaseUltimateRewardsPoints +
      lyftPurchasesPoints +
      diningPoints +
      onlineGroceryPurchasesPoints +
      selectStreamingServicesPoints +
      otherTravelPurchasesPoints +
      allOtherPurchasesPoints;

    const anniversaryBonus = total * 0.1;

    return {
      travelViaChaseUltimateRewards: travelViaChaseUltimateRewardsPoints,
      lyftPurchases: lyftPurchasesPoints,
      dining: diningPoints,
      onlineGroceryPurchases: onlineGroceryPurchasesPoints,
      selectStreamingServices: selectStreamingServicesPoints,
      otherTravelPurchases: otherTravelPurchasesPoints,
      allOtherPurchases: allOtherPurchasesPoints,
      total,
      anniversaryBonus,
    };
  }, [spendCategories]);

  const anniversaryBonusValue = useMemo(() => {
    if (cpp) {
      return (
        ((multipliersReccuringInterval === 'Monthly'
          ? pointsEarned.anniversaryBonus * 12
          : pointsEarned.anniversaryBonus) *
          cpp) /
        100
      );
    }
    return 0;
  }, [cpp, multipliersReccuringInterval, pointsEarned.anniversaryBonus]);

  const categoriesSpendValue = useMemo(() => {
    if (cpp) {
      return (
        ((multipliersReccuringInterval === 'Monthly'
          ? pointsEarned.total * 12
          : pointsEarned.total) *
          cpp) /
        100
      );
    }
    return 0;
  }, [cpp, pointsEarned.total, multipliersReccuringInterval]);

  const totalValuePerYear = useMemo(() => {
    if (
      !state.redemption.selectedOption ||
      (state.redemption.selectedOption === 'custom' &&
        !state.redemption.customValue) ||
      cpp === 0 ||
      customCPPError
    )
      return 0;
    return totalPerksValue + categoriesSpendValue - annualFee;
  }, [
    totalPerksValue,
    categoriesSpendValue,
    state.redemption,
    annualFee,
    customCPPError,
    cpp,
  ]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="Chase Sapphire Preferred® Card"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="Chase Sapphire Preferred® Card Intro Offer"
            colorClassName="border-b-theme-blue-dark"
            introOffer={calculator.data?.attributes.introOfferSection}
            isMR={false}
            publicOfferAmountSuffix=" Chase Ultimate Rewards points (UR) + $300 Chase Travel℠ credit"
            publicOfferInputLabelPrefix="UR"
            belowPointsEarnedContent={
              calculator.data?.attributes.introOfferSection
                .belowPointsEarnedContent && (
                <HTMLContent
                  content={
                    calculator.data?.attributes.introOfferSection
                      .belowPointsEarnedContent
                  }
                  className="prose-sm prose-p:text-xs"
                />
              )
            }
          />
        )}
      <CalculatorSection
        heading="Point Multipliers: Category Spend"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 flex-col gap-10 px-6 py-10">
            <div className="flex justify-end">
              <Toggle
                options={multipliersReccuringIntervalOptions}
                onChange={setMultipliersRecurringInterval}
                value={multipliersReccuringInterval === 'Yearly' ? 0 : 1}
                selectorClassName="bg-theme-blue-dark"
                selectedOptionClassName="text-white"
              />
            </div>
            {spendCategoryItems.map((spendCategoryItem, idx) => (
              <CalculatorInputGroup
                key={`${spendCategoryItem.label}_${idx}`}
                className={classNames(idx > 2 && '2xl:col-span-2')}
                {...spendCategoryItem}
                stateObj={spendCategories}
                stateKey="spendCategories"
                dispatch={dispatch}
                labelInputContainerClassName="sm:flex-row"
              />
            ))}
          </div>
          <div className="flex basis-1/2 items-center justify-center gap-3 px-6 py-12">
            <div className="flex flex-grow flex-col justify-center gap-12 px-6 py-12">
              <p>
                {multipliersReccuringInterval} points earned from spend
                breakdown:
              </p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={pointsEarned.travelViaChaseUltimateRewards}
                  comparisonValue={pointsEarned.total}
                  label="5X Travel via Chase Travel℠"
                  colorClassName="bg-theme-blue-dark"
                  suffix="UR"
                />
                <ValueWithBar
                  value={pointsEarned.lyftPurchases}
                  comparisonValue={pointsEarned.total}
                  label="5X Lyft"
                  colorClassName="bg-[#FF77B4]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={pointsEarned.dining}
                  comparisonValue={pointsEarned.total}
                  label="3X Dining"
                  colorClassName="bg-[#C4FAFA]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={pointsEarned.onlineGroceryPurchases}
                  comparisonValue={pointsEarned.total}
                  label="3X Online grocery"
                  colorClassName="bg-[#7A65FA]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={pointsEarned.selectStreamingServices}
                  comparisonValue={pointsEarned.total}
                  label="3X Select streaming services"
                  colorClassName="bg-[#BCB2F9]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={pointsEarned.otherTravelPurchases}
                  comparisonValue={pointsEarned.total}
                  label="2X Other travel purchases"
                  colorClassName="bg-[#538DFF]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={pointsEarned.allOtherPurchases}
                  comparisonValue={pointsEarned.total}
                  label="1X All other purchases"
                  colorClassName="bg-[#FF77B4]"
                  suffix="UR"
                />
              </div>
              <div className="flex justify-between">
                <span>
                  Total points earned per{' '}
                  {multipliersReccuringInterval === 'Monthly'
                    ? 'month'
                    : 'year'}
                  :
                </span>
                <span className="text-theme-blue-dark">
                  {formatInt(Math.floor(pointsEarned.total))} UR
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-1">
                  <div className="w-fit">10% Anniversary points bonus</div>
                  <Tooltip
                    content={
                      <span className="text-sm">
                        Each account anniversary year, you'll earn bonus points
                        that equal 10% of your total spend in points from
                        purchases made with your credit card during the previous
                        account anniversary year at a rate of 1 point for each
                        $1 spent.
                      </span>
                    }
                    childrenClassName="p-1"
                  >
                    <IoInformationCircleOutline />
                  </Tooltip>
                </div>
                <span className="text-theme-blue-dark">
                  {formatInt(Math.floor(pointsEarned.anniversaryBonus))} UR
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Other Perks and  Benefits: How do you value them?"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 grid-cols-2 flex-col gap-5 px-6 pb-5 pt-10 2xl:grid 2xl:gap-0">
            {perksItems.map((perksItem, idx) => (
              <CalculatorInputGroup
                key={`${perksItem.label}_${idx}`}
                {...perksItem}
                stateObj={perks}
                stateKey="perks"
                dispatch={dispatch}
                error={perksError}
                setError={setPerksError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center gap-3 px-6 py-12">
            <div className="flex flex-grow flex-col justify-center gap-12 px-6 py-12">
              <p>Value of other perks and benefits:</p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={hotelStaysViaChaseUltimateRewards}
                  comparisonValue={totalPerksValue}
                  label="Hotel credit"
                  colorClassName="bg-theme-blue-dark"
                  prefix="$"
                />
                <ValueWithBar
                  value={partnerPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Partner benefits"
                  colorClassName="bg-[#538DFF]"
                  prefix="$"
                />
                <ValueWithBar
                  value={travelPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Travel benefits"
                  colorClassName="bg-[#C4FAFA]"
                  prefix="$"
                />
              </div>
              <div className="flex justify-between">
                <span>Total value:</span>
                <span className="text-theme-blue-dark">
                  ${formatInt(Math.floor(totalPerksValue))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="How do you plan on redeeming points?"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 flex-col gap-5 px-6 py-10">
            <RedemptionRadios
              redemptionItems={redemptionItems}
              redemption={state.redemption}
              dispatch={dispatch}
              setCustomCPPError={setCustomCPPError}
              customCPPError={customCPPError}
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col justify-center gap-12 p-6">
              <h4>Given how you value points...</h4>
              <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                  <span>Value of points from intro offer:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.floor(bonusValue))}
                  </span>
                </div>
                {earnedIntroOffer && (
                  <div className="flex justify-between">
                    <span>Chase Travel℠ credit:</span>
                    <span className="text-theme-blue-dark">$300</span>
                  </div>
                )}
                <div className="flex justify-between">
                  <span>Value from category spend:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.floor(categoriesSpendValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value of perks and benefits:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.floor(totalPerksValue))}
                  </span>
                </div>
              </div>
            </div>
            <ExpectedValue
              heading="Expected Value of Points"
              earnedIntroOffer={earnedIntroOffer}
              totalValuePerYear={totalValuePerYear}
              year2Value={totalValuePerYear + anniversaryBonusValue}
              bonusValue={bonusValue + 300}
              calculationExplanationText="(Intro offer + Chase Travel℠ credit + spend + benefits - annual fee)"
              containerClassName="text-white"
              bgColorClassName="bg-theme-blue-dark"
              headingColorClassName="text-white"
            />
          </div>
        </div>
      </CalculatorSection>
    </CalculatorStructure>
  );
}
